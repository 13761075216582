// Layout or global application sizing
// This file is strictly variable declaration and has no (or shouldn't have any) dependencies
// $app-width: 1400px;
$app-width: var(--app-width-max);

$app-header-height: 86px;
$app-header-secondary-height: 50px;
$app-footer-height: 200px;
$app-side-nav-width: 260px;

$app-gutter-global: 2rem; // 1rem = 15px;
$padding-global: 1.25rem; // 1rem = 15px;

$photo-size-sm: 3rem;
$photo-size-md: 4rem;
$photo-size-lg: 6.25rem;

$header-notification-item-width: 4rem;

// Styles
$style-border-radius: 6px;
$style-profile-pic-xs: 2rem;
$style-profile-pic-sm: 3rem;
$style-profile-pic-md: 94px;
$style-profile-pic-lg: 140px;
$style-profile-pic-xl: 200px;
$style-profile-pic: $style-profile-pic-md;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 0px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / large phone
$screen-sm: 576px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / tablets
$screen-md: 768px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / desktops
$screen-lg: 992px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// x-large screen / wide desktop
$screen-xl: 1200px !default;
$screen-xl-min: $screen-xl !default;
$screen-xl-desktop: $screen-xl-min !default;

// xx-large screen / wide desktop
$screen-xxl: $app-width !default;
$screen-xxl-min: $screen-xxl !default;
$screen-xxl-desktop: $screen-xxl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;
$screen-xl-max: ($screen-xxl-min - 1) !default;

// Grid system
// Number of columns in the grid.
$grid-columns: 12 !default;

// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default; // 15px
// Navbar collapse
// Point at which the navbar becomes un-collapsed.
$grid-float-breakpoint: $screen-sm-min !default;

// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Container sizes
$container-xs: (0px) !default;
$container-sm: (576px) !default;
$container-md: (768px) !default;
$container-lg: (992px) !default;
$container-xl: (1200px) !default;
$container-xxl: (1440px) !default;

// Margins under all headers - Not too certain if this should be a global variable or if it should be header specific
$header-margin-bottom: 1rem;
