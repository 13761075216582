@import '../../stylesheets/themes/_globals/colors/base.scss';

pre {
  page-break-inside: avoid;
  font-family: monospace;
  max-width: 100%;
  border: 1px solid $color-input-border;
  border-radius: 0;
  line-height: 1.5rem;
  background-color: $color-grey-three;
  background-origin: content-box;
  border-left: 10px solid $color-content-light;
  padding: 1.5rem;
  text-align: justify;
  margin: 1rem 0 2rem 0;
}
