// Configure some basics that got 'reset'

html,
body {
  font-family: $font-primary;
  color: $color-content;
  // background-color: var(--color-app-bg);
}

main {
  display: flex;
  // margin-top: $app-header-height + $app-header-secondary-height; // Add up two headers
}

aside {
  // padding: 1.5rem;
}

aside,
section {
  max-height: 100%;
  overflow: auto;
}

section {
  min-width: 100%;
  min-height: calc(100vh - 160px);
  flex: 3;
  padding: 2rem;
}

.container-view {
  position: relative;
  width: 100%;
  min-height: 100vh;

  .container-content {
    padding: $padding-global 0;
    @media screen and (min-width: 640px) {
      padding: $padding-global $app-gutter-global;
    }

    display: flex;
    // width: var(--app-width-max);
    // width: 100%;
    max-height: 100%;
    margin: 0 auto;
    // margin-left: 0;
    min-height: calc(100vh - 160px);

    overflow: auto;

    // side-nav
    // margin-left: 0;
    transition: margin-left 0.15s ease-in-out 0s;

    &.nav-open {
      margin-left: $app-side-nav-width;
      transition: margin-left 0.15s ease-in-out 0s;
    }

    .left {
      width: $app-side-nav-width;
      min-width: $app-side-nav-width;
    }

    .right {
      width: 100%;
      padding-left: 1.5rem;
    }

    &.employer-margin-top {
      margin-top: 24px; // @Todo - fix with tw. arbitrary number fix didn't work first attempt
    }
  }

  &.layout-sidebar {
    display: flex;

    aside {
      position: fixed;
      min-height: 100%;
      max-height: 100%;
      height: 100%;
      background-color: $color-primary;
    }

    main {
      margin-top: 0;

      .container-content {
        min-height: auto;
        overflow: visible;

        min-height: calc(100vh - 175px); // No magic numbers.  175 need to be header height plus footer height
      }

      .content {
        width: auto;
      }
    }
  }
}

// Globalize these!!! Retro fit into starter app - Testing here
$content-left: 260px;
$content-main: 100%;
$content-right: 260px;

.content {
  &.content-split {
    @media screen and (min-width: 640px) {
      display: flex;
    }

    .content-left {
      flex: 0 1 $content-left;
      display: flex;
      flex-direction: column;
      // width: $content-left;
      // min-width: $content-left;
      // margin-left: 25px;
      // margin-right: 25px;
    }

    .content-main {
      margin: 0 25px;
      @media screen and (min-width: 640px) {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: $content-main;
        // padding-left: 1.5rem;
        margin: 0 0;
      }
    }

    .content-right {
      display: flex;
      width: $content-right;
      min-width: $content-right;
    }
  }
}
